import { gql } from '@apollo/client'

export default gql`
  query upcomingSubscribedLots {
    upcomingSubscribedLots {
      lots {
        id
        name
        lotUrl
        dates {
          id
          date
          locations {
            id
            truckName
            foodImage {
              original
              present
            }
          }
        }
      }
    }
  }
`
