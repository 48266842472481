import Image from 'components/Image'
import AppIcon from 'static/images/app-icon200x200.png'
import AppStoreIcon from 'static/images/apple-appstore.svg'
import GooglePlayStoreIcon from 'static/images/google-playstore.svg'

export default function AppAds() {
  return (
    <div className="flex justify-center py-16 px-4">
      <div
        className="flex w-full justify-between"
        style={{ maxWidth: '500px' }}
      >
        <div className="w-1/4 md:block hidden">
          <Image
            alt="bestfoodtrucks logo"
            className="rounded-3xl"
            height={100}
            src={AppIcon}
            width={100}
          />
        </div>
        <div className="md:w-3/4">
          <div className="flex justify-start">
            <div className="w-1/4 md:hidden block">
              <Image
                alt="bestfoodtrucks logo"
                className="rounded-xl"
                height={70}
                src={AppIcon}
                width={70}
              />
            </div>
            <div className="md:w-full w-3/4 font-medium md:text-2xl text-lg">
              Get the full experience via our app
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-1/2 pr-2 cursor-pointer">
              <a href="https://apps.apple.com/us/app/best-food-trucks/id1513823045">
                <Image
                  alt="ios app"
                  className="rounded-lg"
                  height={50}
                  src={AppStoreIcon}
                  width={175}
                />
              </a>
            </div>
            <div className="w-1/2 pr-2 cursor-pointer">
              <a href="https://play.google.com/store/apps/details?id=com.bftcustomers&hl=en">
                <Image
                  alt="android app"
                  className="rounded-lg"
                  height={50}
                  src={GooglePlayStoreIcon}
                  width={175}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
