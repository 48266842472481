import React from 'react'
import Link, { LinkProps } from 'next/link'
import { ctaButtonClass } from 'components/ui/CtaButton'

type Props = {
  children: React.ReactElement | string | (React.ReactElement | string)[]
  fullWidth?: boolean
  target?: '_blank' | '_self'
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void
  role?: string
} & React.PropsWithChildren<LinkProps>

export default function CtaLink({
  children,
  target = '_self',
  fullWidth,
  onClick,
  onKeyDown,
  role = 'button',
  ...linkProps
}: Props) {
  return (
    // eslint-disable-next-line react/button-has-type
    <Link
      {...linkProps}
      className={ctaButtonClass({ fullWidth })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      target={target}
    >
      {children}
    </Link>
  )
}
