import React from 'react'
import cn from 'classnames'

type ButtonProps = {
  fullWidth?: boolean
  children: React.ReactElement | string | (React.ReactElement | string)[]
}

export function ctaButtonClass({ fullWidth }: { fullWidth?: boolean }) {
  return cn(CTA_BUTTON_CLASS, {
    'w-full': fullWidth
  })
}

const CTA_BUTTON_CLASS =
  'flex items-center justify-center px-8 py-3 rounded-md shadow bg-gradient-to-r from-orange-700 to-orange-500 text-white font-medium hover:from-orange-600 hover:to-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 focus:ring-offset-gray-900 md:py-4 md:text-lg md:px-10'

export default function CtaButton({
  children,
  fullWidth,
  type,
  ...props
}: ButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) {
  return (
    <button
      className={ctaButtonClass({ fullWidth })}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}
