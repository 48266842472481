import Image from 'components/Image'
import React from 'react'
import Link from 'next/link'

const portfolioItems = [
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/maravilla-salmon.jpg'),
    title: 'Salmon Plate',
    truck: 'Maravilla'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/boba-teryaki.jpg'),
    title: 'Teryaki Fish Rice Bowl',
    truck: 'Boba Ni Taco'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/cousins-maine-roll.jpg'),
    title: 'Maine Roll',
    truck: 'Cousins Maine Lobster'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/stopbye-justin.jpg'),
    title: "Justin's 18 Spice Chicken",
    truck: 'StopBye Cafe'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/pov-conchiglie.jpg'),
    title: 'Conchiglie Alla Norma',
    truck: 'Prince of Venice'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/yuna-kimchi.jpg'),
    title: 'Kimchi Fried Rice',
    truck: "Yuna's Bob"
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/fix-pesto.jpg'),
    title: 'Pesto Burger',
    truck: 'The Fix On Wheels'
  },
  {
    // eslint-disable-next-line global-require
    imageUrl: require('images/portfolio/goshen-shore.jpg'),
    title: 'Goshen Shore Bowl',
    truck: 'Goshen'
  }
]

const FoodExamples = () => (
  <div className="relative">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 border-t border-solid border-gray-200 pt-16 sm:pt-24">
      <h2 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Remember what fresh tasted like?
      </h2>
      <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
        These are real photos from real food trucks on our platform. <br /> All
        food served fresh, made-to-order.
      </p>
    </div>
    <div className="mt-12 grid sm:grid-cols-4 grid-cols-2">
      {portfolioItems.map((item) => (
        <div key={item.title} className="relative group">
          <Image
            alt={item.title}
            className="object-cover object-center group-hover:scale-110 transition-all duration-500"
            layout="responsive"
            sizes="25vw"
            src={item.imageUrl}
          />
          <Link href="/food-trucks-near-me">
            <div className="bg-opacity-0 opacity-0 group-hover:bg-opacity-80 group-hover:opacity-100 transition-all duration-500 absolute top-0 left-0 right-0 bottom-0 bg-black flex justify-center items-center flex-col cursor-pointer">
              <p className="text-white text-center text-lg lg:text-2xl font-bold tracking-tight">
                {item.title}
              </p>
              <p className="text-orange-600 text-center lg:text-xl font-bold mt-3">
                {item.truck}
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  </div>
)

export default FoodExamples
