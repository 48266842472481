import { ReactElement } from 'react'
import cn from 'classnames'

type DataPoint = {
  label: string
  value: string
}

type Props = {
  title: string | ReactElement
  description?: string | ReactElement
  points: DataPoint[]
}

const StatsPoints = ({ points }: { points: DataPoint[] }) => (
  <dl
    className={cn('mt-10 text-center sm:mx-auto grid sm:gap-8 gap-y-6', {
      'grid-cols-3': points.length === 3,
      'grid-cols-2  sm:grid-cols-4': points.length >= 4
    })}
  >
    {points.map((p) => (
      <div key={p.label} className="flex flex-col">
        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-orange-100">
          {p.label}
        </dt>
        <dd className="order-1 text-2xl sm:text-3xl lg:text-5xl font-extrabold text-white">
          {p.value}
        </dd>
      </div>
    ))}
  </dl>
)

const StatsSection = ({ title, description, points }: Props) => {
  return (
    <div className="bg-gradient-to-br lg:bg-gradient-to-r from-orange-600 via-orange-400 to-orange-600 max-w-none w-full">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            {title}
          </h2>
          {description && (
            <p className="mt-3 text-xl text-orange-100 sm:mt-4">
              {description}
            </p>
          )}
        </div>
        <StatsPoints points={points} />
      </div>
    </div>
  )
}

export default StatsSection
