import { useEffect } from 'react'

type Props = {
  previewId: string
  videoId: string
  className?: string
}
export default function LiteVimeo({ previewId, videoId, className }: Props) {
  const supportsCustomElements =
    typeof window === 'undefined' || 'customElements' in window

  useEffect(() => {
    if (!supportsCustomElements) return

    import('utils/liteVimeo')
  }, [supportsCustomElements])

  if (!supportsCustomElements) {
    return (
      <iframe
        allow="autoplay; fullscreen; picture-in-picture"
        className={className}
        frameBorder="0"
        loading="lazy"
        src={`https://player.vimeo.com/video/${videoId}`}
        title="best food trucks"
        allowFullScreen
      />
    )
  }

  // get preview id https://depone.dev/video/
  return (
    <>
      {/* @ts-ignore */}
      <lite-vimeo class={className} previewid={previewId} videoid={videoId} />
    </>
  )
}
