import React from 'react'
import HERO_IMAGE from 'images/home-hero.jpg'
import { ClockIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import CtaLink from 'components/ui/CtaLink'

const HeroSection = () => {
  return (
    <div className="relative w-full h-screen">
      <div
        className="absolute inset-0 parallax bg-center"
        style={{ backgroundImage: `url('${HERO_IMAGE.src}')` }}
      >
        <div className="absolute bg-black inset-0 bg-opacity-40" />
      </div>
      <div className="relative h-screen px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 md:max-w-screen-xl mx-auto flex justify-center items-center flex-col">
        <h1
          className="uppercase text-4xl text-center font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-orange-600"
          style={{
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          BEST FOOD TRUCKS
        </h1>
        <p className="mt-5 text-3xl font-extrabold tracking-tight ext-4xl text-center sm:text-5xl text-white">
          Want Food Trucks at Your Office,
          <br /> Neighborhood or Event?
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-white text-center font-medium sm:max-w-3xl">
          We’ll set up a regular food truck program that fits your every need.
          <br />
          You can browse menus, order online and get texted when your food is
          ready.
        </p>

        <div className="mt-16">
          <CtaLink href="/hire-food-truck">
            <ClockIcon className="w-4 h-4 md:w-6 md:h-6 mr-4 text-white" /> Hire
            a food truck
          </CtaLink>
        </div>
        <div className="mt-5">
          <CtaLink href="/food-trucks-near-me">
            <LocationMarkerIcon className="w-4 h-4 md:w-6 md:h-6 mr-4 text-white" />{' '}
            Order now from trucks near me
          </CtaLink>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
