import {
  CheckCircleIcon,
  ClockIcon,
  TruckIcon,
  HeartIcon
} from '@heroicons/react/outline'

const features = [
  {
    name: 'Take Back your Lunch Hour',
    description:
      'Skip the line, order ahead, get texted when your food is ready. No waiting in line means more time for work/play.',
    linkText: 'How does it work?',
    linkHref: '/faq/catering/how-does-best-food-trucks-work',
    icon: ClockIcon
  },
  {
    name: 'Fresher than Delivery',
    description:
      'Our food is made-to-order, unlike delivery which is made 45+ minutes before you eat.',
    linkText: 'See examples',
    linkHref: '/food-trucks-near-me',
    icon: CheckCircleIcon
  },
  {
    name: 'Less Waste than Catering',
    description:
      'Stop throwing out 25% of your food! We only cook what people actually eat. Easier on the planet (and your budget)',
    linkText: 'Upgrade your catering experience',
    linkHref:
      '/faq/catering/what-if-my-office-or-business-already-has-catering',
    icon: HeartIcon
  },
  {
    name: 'Employer Paid Lunch Program',
    description:
      'Keep your employees happy and productive. Seamless integration, less food waste, more variety and cheaper bill.',
    linkText: 'Employer-paid lunch signup',
    linkHref: '/hire-food-truck',
    icon: TruckIcon
  }
]

const FeaturesSection = () => (
  <div className="relative bg-white py-16 sm:py-24 lg:pt-32">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 className="text-base font-semibold uppercase tracking-wider text-orange-600">
        Full solution for and by food trucks
      </h2>
      <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Connecting gourmet food trucks with customers
      </p>
      <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
        We&apos;ve built a unique software solution to streamline the process of
        booking and scheduling trucks with the capability of offering an
        exclusive online order ahead technology for customers.
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6 flex">
              <div className="rounded-lg bg-gray-50 px-4 pb-8 flex">
                <div className="-mt-6 flex-grow">
                  <div className="flex flex-col justify-between h-full">
                    <div className="">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-orange-400 to-orange-600 p-3 shadow-lg">
                          <feature.icon
                            aria-hidden="true"
                            className="h-6 w-6 text-white"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                    <div className="cursor-pointer text-orange-500 hover:underline hover:text-orange-600 duration-200">
                      <a href={feature.linkHref}>
                        <p className="mt-5 text-xs font-medium tracking-tight uppercase">
                          {feature.linkText}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default FeaturesSection
