import PageMeta from 'components/PageMeta'
import React from 'react'
import PageLayout from 'components/pages/PageLayout'
import { withApollo } from 'components/withApollo'
import { NextPage } from 'next'
import { ClientLogos } from 'components/ClientClouds'
import StatsSection from 'components/StatsSection'
import VideoSection from 'components/pages/HomePage/VideoSection'
import AppAds from 'components/AppAds'
import FeaturesSection from 'components/pages/HomePage/FeaturesSection'
import FoodExamples from 'components/pages/HomePage/FoodExamples'
import HeroSection from 'components/pages/HomePage/HeroSection'
import LotSchedule from 'components/pages/HomePage/LotSchedule'

import { useHomePageStructuredData } from './types/HomePageStructuredData'

const HomePage = () => {
  const { data } = useHomePageStructuredData({
    fetchPolicy: 'cache-first'
  })
  return (
    <PageLayout fullWidth staticPage transparentOnTop>
      <PageMeta
        canonicalPath="/"
        description="Find Food Trucks Near Me"
        ldJson={data?.structuredData?.homePage}
        title="Best Food Trucks (BFT)"
      />
      <div className="w-full">
        <HeroSection />
        <ClientLogos
          clients={[
            'pfizer',
            'boeing',
            'apple',
            'amazon',
            'cushman',
            'aramark',
            'compass',
            'swedish'
          ]}
        />
        <StatsSection
          description="Best Food Trucks works with over a thousand trucks across the country. Whether you’re looking for food trucks in Los Angeles, Austin, Portland, D.C., or dozens more cities across the country, BFT will find the right trucks for you."
          points={[
            {
              label: 'Cities',
              value: '1,900'
            },
            {
              label: 'Food Trucks',
              value: '5,400'
            },
            {
              label: 'Bookings',
              value: '80,000'
            },
            {
              label: 'Happy Customers',
              value: '200,000'
            }
          ]}
          title="Trusted by customers from over 36 states"
        />
        <LotSchedule />
        <FeaturesSection />
        <FoodExamples />
        <VideoSection />
        <AppAds />
      </div>
    </PageLayout>
  )
}

export default withApollo({ ssr: false })(HomePage as NextPage)
