import { useQuery } from '@apollo/client'
import Image from 'components/Image'
import React from 'react'
import ButtonLink from 'components/ui/ButtonLink'

import {
  upcomingSubscribedLots,
  upcomingSubscribedLots_upcomingSubscribedLots_lots_dates
} from './types/upcomingSubscribedLots'
import QUERY from './lotsQuery'

const ShiftCards = ({
  dates
}: {
  dates: upcomingSubscribedLots_upcomingSubscribedLots_lots_dates[]
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-7">
    {dates.map((date, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={i} className="bg-white rounded-lg shadow lg:p-6 p-2">
        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-orange-800 text-xs font-medium bg-orange-200 rounded-full">
          {date.date}
        </span>
        <ul className="divide-y divide-dashed divide-gray-200 mt-2 grid grid-cols-1">
          {date.locations.map((shift) => (
            <li key={shift.id} className="py-2">
              <div className="w-full flex items-center justify-between space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">
                      {shift.truckName}
                    </h3>
                  </div>
                </div>

                {shift.foodImage?.present && (
                  <div className="relative rounded-full">
                    <Image
                      className="rounded-full flex-shrink-0"
                      height={60}
                      src={shift.foodImage.original!}
                      width={60}
                    />
                    <div
                      className="absolute inset-0 bg-orange-700 bg-opacity-10 rounded-full"
                      style={{ width: '60px', height: '60px' }}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
)

const LotSchedule = () => {
  const { data } = useQuery<upcomingSubscribedLots>(QUERY, {
    fetchPolicy: 'cache-first',
    skip: typeof window === 'undefined'
  })

  if (
    !data?.upcomingSubscribedLots.lots ||
    data?.upcomingSubscribedLots.lots.length === 0
  )
    return null

  return (
    <div className="relative bg-white py-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <p className="text-base font-semibold uppercase tracking-wider text-orange-600">
          Schedule
        </p>
        <h2 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          My Locations
        </h2>

        <div className="mt-12">
          <div className="flex flex-col space-y-12">
            {data?.upcomingSubscribedLots.lots.map((lot) => (
              <div
                key={lot.id}
                className="p-4 lg:p-8 bg-gray-50 shadow rounded-xl"
              >
                <h3 className="text-xl font-bold text-orange-700">
                  {lot.name}
                </h3>
                <ShiftCards dates={lot.dates} />
                <div className="mt-6">
                  <ButtonLink
                    color="primary"
                    href={lot.lotUrl}
                    variant="contained"
                  >
                    Full Schedule, Menu and Order Ahead
                  </ButtonLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LotSchedule
