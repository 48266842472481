import LiteVimeo from 'components/LiteVimeo'

const VideoSection = () => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-24 sm:pt-32 px-4 sm:px-6 lg:px-8 border-b border-solid border-b-1 border-gray-200">
        <div className="mt-8 lg:grid lg:grid-cols-8 lg:gap-8">
          <div className="col-span-3">
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <div>
                <p className="text-base text-orange-600 font-semibold tracking-wide uppercase">
                  Online orders
                </p>
                <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Skip the line, order ahead
                </h2>
              </div>
            </div>
            <div className="my-8 text-base max-w-prose mx-auto prose lg:max-w-none">
              <p className="text-lg text-gray-500">
                All of our food trucks have Order Ahead enabled, so you can see
                full schedules, browse menus, and order ahead from your computer
                or phone.
              </p>
              <p className="text-lg text-gray-500">
                We&apos;ll text you with a time estimate and again when your
                food is ready so you don&apos;t have to wait around. Now you can
                relax or get more work done!
              </p>
            </div>
          </div>
          <div className="lg:col-span-5">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <div className="relative shadow-xl rounded overflow-hidden w-full aspect-w-16 aspect-h-9">
                <LiteVimeo
                  previewId="1394218120-0dae670b8558966d13bd56ce35455b3eb6145970061f8c69ca17af69f6145112-d"
                  videoId="1023357838"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoSection
