import mcCormickLogo from 'images/clients-logo/mc-cormick-logo.png'
import albertsonsLogo from 'images/clients-logo/albertsons.png'
import appleLogo from 'images/clients-logo/apple-logo.png'
import cbsLogo from 'images/clients-logo/cbs-logo.png'
import brookfieldLogo from 'images/clients-logo/brookfield.png'
import claremontLogo from 'images/clients-logo/claremont-logo.png'
import swedishLogo from 'images/clients-logo/swedish-logo.png'
import pfizerLogo from 'images/clients-logo/pfizer-logo.png'
import amazonLogo from 'images/clients-logo/amazon_logo.png'
import boeingLogo from 'images/clients-logo/boeing-logo.png'
import disneyLogo from 'images/clients-logo/disney_logo.png'
import spaceXLogo from 'images/clients-logo/spacex-logo.png'
import teslaLogo from 'images/clients-logo/tesla-logo.png'
import cushLogo from 'images/clients-logo/cushman-and-wakefield.png'
import compassLogo from 'images/clients-logo/compass-group.png'
import aramarkLogo from 'images/clients-logo/aramark.png'
import Image from 'components/Image'
import React from 'react'
import take from 'lodash/take'
import cn from 'classnames'

const CLIENTS = {
  amazon: {
    name: 'Amazon',
    image: amazonLogo,
    height: 75,
    width: 150
  },
  boeing: {
    name: 'Boeing',
    image: boeingLogo,
    height: 75,
    width: 150
  },
  disney: {
    name: 'Disney',
    image: disneyLogo,
    height: 75,
    width: 150
  },
  spacex: {
    name: 'spaceX',
    image: spaceXLogo,
    height: 75,
    width: 150
  },
  tesla: {
    name: 'Tesla',
    image: teslaLogo,
    height: 75,
    width: 150
  },
  apple: {
    name: 'Apple',
    image: appleLogo,
    height: 100,
    width: 100
  },
  pfizer: {
    name: 'Pfizer',
    image: pfizerLogo,
    height: 100,
    width: 120
  },
  mcCormick: {
    name: 'McCormick',
    image: mcCormickLogo,
    height: 90,
    width: 90
  },
  albertsons: {
    name: 'Albertsons',
    image: albertsonsLogo,
    height: 90,
    width: 90
  },
  cbs: {
    name: 'CBS',
    image: cbsLogo,
    height: 100,
    width: 100
  },
  brookfield: {
    name: 'Brookfield',
    image: brookfieldLogo,
    height: 100,
    width: 120
  },
  swedish: {
    name: 'Swedish Hospitals',
    image: swedishLogo,
    height: 100,
    width: 120
  },
  claremont: {
    name: 'Claremont',
    image: claremontLogo,
    height: 100,
    width: 120
  },
  cushman: {
    name: 'Cushman & Wakefield',
    image: cushLogo,
    height: 100,
    width: 160
  },
  compass: {
    name: 'Compass Group',
    image: compassLogo,
    height: 100,
    width: 150
  },
  aramark: {
    name: 'Aramark',
    image: aramarkLogo,
    height: 100,
    width: 150
  }
}

type Props = {
  clients: (keyof typeof CLIENTS)[]
  className?: string
}

export const ClientLogos = ({ clients, className }: Props) => (
  <div
    className={cn(
      'relative grid grid-cols-4 gap-0.5 md:grid-cols-8',
      className
    )}
  >
    {clients.map((client) => (
      <div
        key={client}
        className="col-span-1 flex justify-center bg-gray-50 py-4 md:px-1 px-2"
      >
        <Image
          alt={`${CLIENTS[client].name} logo`}
          className="object-contain object-center w-full"
          height={CLIENTS[client].height}
          src={CLIENTS[client].image}
          width={CLIENTS[client].width}
        />
      </div>
    ))}
  </div>
)

const ClientClouds = ({ clients, className }: Props) => (
  <div className={cn('bg-white', className)}>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 text-center">
      <p className="mt-2 text-lg text-gray-900 tracking-tight mx-auto">
        You&apos;re in a good company! We&apos;ve helped such household names as{' '}
        {take(clients, 3)
          .map((c) => CLIENTS[c].name)
          .join(', ')}{' '}
        and many more to advance their brand.
      </p>
      <div className="mt-6 lg:mt-8">
        <ClientLogos clients={clients} />
      </div>
    </div>
  </div>
)

export default ClientClouds
