/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../../global-graphql-types'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from 'utils/extendApolloHooks'
const defaultOptions = {} as const
export type HomePageStructuredDataVariables = Types.Exact<{
  [key: string]: never
}>

export type HomePageStructuredData = {
  __typename: 'Query'
  structuredData: {
    __typename: 'StructuredData'
    homePage: Array<string> | null
  }
}

export const HomePageStructuredDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HomePageStructuredData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'homePage' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useHomePageStructuredData__
 *
 * To run a query within a React component, call `useHomePageStructuredData` and pass it any options that fit your needs.
 * When your component renders, `useHomePageStructuredData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageStructuredData({
 *   variables: {
 *   },
 * });
 */
export function useHomePageStructuredData(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePageStructuredData,
    HomePageStructuredDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    HomePageStructuredData,
    HomePageStructuredDataVariables
  >(HomePageStructuredDataDocument, options)
}
export function useHomePageStructuredDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HomePageStructuredData,
    HomePageStructuredDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    HomePageStructuredData,
    HomePageStructuredDataVariables
  >(HomePageStructuredDataDocument, options)
}
export type HomePageStructuredDataHookResult = ReturnType<
  typeof useHomePageStructuredData
>
export type HomePageStructuredDataLazyQueryHookResult = ReturnType<
  typeof useHomePageStructuredDataLazyQuery
>
export type HomePageStructuredDataQueryResult = Apollo.QueryResult<
  HomePageStructuredData,
  HomePageStructuredDataVariables
>
